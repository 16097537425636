<template>
  <div class="paper-question-select-layout" :style="questionLength">
    <div class="wrapper">
      <div class="filter-section">
        <div class="form-wrapper">
          <InputWrapper label="选择题库">
            <Select
              v-model="selectedQuestionBankId"
              placeholder="请选择"
              @on-change="handleQuestionBankChange(selectedQuestionBankId, (keyword = ''))"
            >
              <Option v-for="question in questionSystem" :key="question.id" :value="question.id">{{ question.name }}</Option>
            </Select>
          </InputWrapper>
          <Select
            style="margin: 10px 0 0 10px;"
            v-model="selectedQuestionSystemBankId"
            v-if="showQuestionExam"
            placeholder="请选择题库"
            @on-change="handleQuestionBankById"
          >
            <Option v-for="questionBank in questionBankList" :key="questionBank.id" :value="questionBank.id">{{
              questionBank.name.substring(0, 7)
            }}</Option>
          </Select>
          <Input
            class="search-bar"
            search
            placeholder="请输入题库关键字"
            v-model="keyword"
            @on-search="handleQuestionBankChange(selectedQuestionBankId)"
          />
          <Input class="search-bar" search placeholder="请输入题目关键字" v-model="questionKeyword" @on-search="handleQuestionBankByKeywords" />
        </div>
        <div class="paper-basket" :class="showOrHid === false ? 'hidden' : ''">
          <Card>
            <div slot="title" class="move-handle" @click="showOrHid = !showOrHid">
              <JYIcon href="#icon-move" id="basket-move-handle" />
              试卷篮
              <span><Icon :type="showOrHid ? 'ios-arrow-up' : 'ios-arrow-down'"/></span>
            </div>
            <div v-if="showOrHid">
              <div v-if="questionTypeStatistic.length" class="statistic-wrapper">
                <div v-for="item in questionTypeStatistic" :key="item.label">{{ item.label }}: {{ item.count }} 题</div>
              </div>
              <Button :disabled="pureQuestionList.length === 0" type="primary" long @click="toPaperEditor">确认</Button>
            </div>
          </Card>
        </div>
      </div>
      <div class="question-section">
        <div class="header">
          <div class="left">排序</div>
          <div class="right">
            共计：{{ questionList.length }}道题
            <Button
              v-if="!isAllSelected && questionList.length !== 0"
              type="primary"
              size="small"
              @click="
                handleSelectAll({
                  questionList,
                  isClassify: getEditorData.isClassify,
                  currentI: getEditorData.currentIndex
                })
              "
              >全选</Button
            >
            <Button v-if="isAllSelected" type="error" size="small" @click="handleUnselectAll(questionList)">取消全选</Button>
          </div>
        </div>
        <div :key="question.id" v-for="(question, index) in questionList" class="question-item" :class="{ 'plural-row': index % 2 === 0 }">
          <div class="header">[{{ questionTypeLabel(question.questionType) }}]</div>
          <div class="subject question-image-size-restrict" v-html="question.stem"></div>
          <div class="footer">
            <div class="left"></div>
            <div class="right">
              <span>难度：{{ difficultyLabel(question.difficulty) }}</span>
              <span>
                <Button
                  v-if="!_isSelected(question.questionId)"
                  type="primary"
                  size="small"
                  @click="
                    handleSelectQuestion({
                      question,
                      isClassify: getEditorData.isClassify,
                      currentI: getEditorData.currentIndex
                    })
                  "
                  >选题</Button
                >
                <Button v-else type="info" size="small" @click="handleSelectQuestion({ question })">取消选题</Button>
              </span>
            </div>
          </div>
        </div>
        <div v-if="questionList.length !== 0" style="float: right;margin-top: 15px;">
          <Page :total="total" :current="page + 1" show-total @on-change="changePage"></Page>
        </div>
        <EmptyStatus v-if="loadingQuestion" icon="#icon-loading-copy" spin hint="加载中" />
        <EmptyStatus v-if="!loadingQuestion && questionList.length === 0" />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionAPI from '@/api/question'
import InputWrapper from '@/components/common/InputWrapper'
import { questionType } from '@/util/questionType'
import { difficulty } from '@util/difficulty'
import JYIcon from '@/components/common/JYIcon'
import { mapMutations, mapState, mapGetters } from 'vuex'
import EmptyStatus from '@/components/common/EmptyStatus'

export default {
  name: 'PaperQuestionSelect',
  components: {
    InputWrapper,
    JYIcon,
    EmptyStatus
  },
  data() {
    return {
      IsSearch: false,
      questionKeyword: '', // 题目关键字
      keyword: '',
      showOrHid: false,
      size: 10,
      page: 0,
      total: 0,
      questionBankId: '',
      questionSystem: [
        {
          id: 1,
          name: '企业题库'
        },
        {
          id: 2,
          name: '收藏题库'
        },
        {
          id: 3,
          name: '公开题库'
        }
      ],
      questionExam: [],
      showQuestionExam: false,
      questionBankList: [],
      questionList: [],
      selectedQuestionBankId: '',
      selectedQuestionSystemBankId: '',
      loadingQuestion: false,
      myBanks: {
        total: 0,
        page: 0,
        data: null
      },
      openBanks: {
        total: 0,
        page: 0,
        data: null
      },
      collectedBanks: {
        total: 0,
        page: 0,
        data: null
      }
    }
  },
  created() {
    this.fetchQuestionBanks()
  },
  mounted() {
    this.selectedQuestionBankId = this.questionSystem[0].id
    if (this.selectedQuestionBankId) {
      this.showQuestionExam = true
      this.handleQuestionBankChange(this.selectedQuestionBankId)
    }
  },
  computed: {
    ...mapState('question', ['selectedQuestionList']),
    ...mapGetters('question', ['questionTypeStatistic', 'pureQuestionList', 'getEditorData']),
    questionLength() {
      return this.questionList.length === 0 ? 'height: 100%' : ''
    },
    isAllSelected() {
      let allSelected = true
      if (this.questionList.length === 0) return false
      for (let i = 0; i < this.questionList.length; i++) {
        let question = this.questionList[i]
        let index = this.pureQuestionList.findIndex(selectedQuestion => selectedQuestion.id === question.id)
        if (index === -1) {
          allSelected = false
          break
        }
      }
      return allSelected
    }
  },
  methods: {
    ...mapMutations('question', ['handleSelectQuestion', 'handleSelectAll', 'handleUnselectAll', 'setEditorData']),
    handleQuestionBankByKeywords() {
      this.keyword = ''
      this.page = 0
      this.searchQuestionKeyword()
    },
    async searchQuestionKeyword() {
      this.IsSearch = true // 是否是关键字搜索题目(分页有用)
      let type = this.getEditorData.type
      let questionSearchList = null
      this.loadingQuestion = true
      this.questionList = []
      questionSearchList = await QuestionAPI.getQuestionByKeyword(this.page, this.size, this.questionKeyword)
      let data = questionSearchList.res.data
      this.total = questionSearchList.res.total
      data.map(item => {
        item.name = item.stem
        item.id = item.questionId
      })
      if (type !== 6) {
        data = data.filter(q => {
          return q.questionType === type
        })
      }
      this.questionList = data
      this.loadingQuestion = false
    },
    toPaperEditor() {
      const paperbankid = this.$route.query['bankId']
      this.setEditorData({
        isClassify: this.getEditorData.isClassify,
        currentIndex: '',
        type: 6
      })
      this.$router.push(`/manage/exam/paper/editor?bankId=${paperbankid}&diff=${this.$route.query.diff}`)
    },
    questionTypeLabel(type) {
      return questionType[type].label
    },
    difficultyLabel(type) {
      return difficulty[type].label
    },
    handleQuestionBankChange(bankid) {
      this.questionKeyword = ''
      this.showQuestionExam = true
      const payload = {
        forked: false, // true 收藏题库 ; false 企业题库
        searchContent: this.keyword
      }
      let sortParam = 'createdTime,desc'
      const data = {
        exceptSelf: true,
        publicity: 1, // 公共题库
        searchContent: this.keyword
      }
      let fetchFunc = null
      switch (bankid) {
        case 1:
          fetchFunc = QuestionAPI.getOpenQuestionBanks(sortParam, 0, 999, payload)
          break
        case 2:
          payload.forked = true
          fetchFunc = QuestionAPI.getOpenQuestionBanks(sortParam, 0, 999, payload)
          break
        case 3:
          fetchFunc = QuestionAPI.getOpenQuestionBanks(sortParam, 0, 999, data)
          break
        default:
          fetchFunc = () => {}
      }
      fetchFunc.then(res => {
        let data = res.res
        this.questionBankList = data.data.map(item => ({
          name: item.name,
          id: item.questionBankId
        }))
        if (this.questionBankList) {
          this.selectedQuestionSystemBankId = this.questionBankList[0].id
          this.handleQuestionBankById(this.selectedQuestionSystemBankId)
        }
      })
    },
    handleQuestionBankById(id) {
      this.questionKeyword = ''
      this.IsSearch = false
      this.questionBankId = id
      this.page = 0
      this.searchQuestions()
    },
    changePage(changePageNum) {
      this.page = changePageNum - 1
      this.IsSearch ? this.searchQuestionKeyword() : this.searchQuestions()
    },
    async fetchQuestionBanks() {
      //todo 请求 200 个，待优化，需后端提供接口
      const { res } = await QuestionAPI.getMyQuestionBanks('', '', 0, 200)
      this.questionBankList = res.data.map(item => ({
        name: item.name,
        id: item.questionBankId
      }))
    },
    async searchQuestions() {
      let bankid = this.questionBankId
      let type = this.getEditorData.type
      this.loadingQuestion = true
      this.questionList = []
      const { res } = await QuestionAPI.searchQuestionsWithAnswer(bankid, this.page, this.size)
      this.total = res.total
      res.data = res.data.map(question => {
        question.questionId = question.id
        return question
      })
      let data = res.data
      if (type !== 6) {
        data = data.filter(q => {
          return q.questionType === type
        })
      }
      this.questionList = data
      this.loadingQuestion = false
    },
    _isSelected(questionId) {
      return this.pureQuestionList.findIndex(question => question.questionId === questionId) !== -1
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin.less';
.paper-question-select-layout {
  padding: 20px;
}
.wrapper {
  height: 100%;
  .--mixin-shadow;
  background: white;
}
.form-wrapper {
  display: flex;
  max-width: 650px;
  .search-bar {
    margin: 10px 0 0 10px;
    ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;

      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }
  }
}
.filter-section {
  position: relative;
  padding: 30px;
}
.question-section {
  height: calc(100% - 102px);
  & > .header {
    padding: 20px;
    height: 60px;
    background: #e4e9ef;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.question-item {
  padding: 5px 20px;
  border-bottom: solid 1px #e3e3e3;
  .header {
    text-align: left;
  }
  .subject {
    text-align: left;
  }
  .footer {
    display: flex;
    min-height: 20px;
    align-items: center;
    justify-content: space-between;
    .right > * + * {
      margin-left: 10px;
    }
  }
  &.plural-row {
    background: #f8f9fb;
  }
}
.paper-basket {
  width: 300px;
  position: absolute;
  text-align: left;
  top: 20px;
  right: 15px;
  .move-handle {
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
      cursor: pointer;
      position: absolute;
      right: 20px;
      font-size: 16px;
      color: #808695;
    }
  }
}
.hidden {
  ::v-deep .ivu-card-body {
    padding: 0;
  }
}
</style>
